<template>
    <!-- Refresher, to be able pull from the top -->
    <ion-refresher
        slot="fixed"
        :pull-factor="0.5"
        :pull-min="100"
        :pull-max="200"
        @ionRefresh="matcher.fetchResults"
    >
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
</template>

<script setup>
const matcher = useMatcher();
</script>

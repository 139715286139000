<template>
    <ion-page>
        <SearchingMatch v-if="matchStore.loading.screen" />

        <ion-content v-else class="--app-layout --safe-area">
            <div class="container">
                <!-- To be able refresh screen when no results are found -->
                <MatchRefresher v-if="!matchStore.currentResult" />

                <!-- Screen, that you found a match after click on accept button -->
                <ActiveMatch
                    v-if="matchStore.activeMatch && currentResult"
                    :event="matchStore.activeMatch"
                    :attendee="currentResult.user"
                    @next="matcher.goToNextResult()"
                />

                <!-- No active searches available, show when user does not come from homepage search and no active searches are present. -->
                <ToggleActiveSearch
                    v-else-if="
                        matchStore.forceSearch === false &&
                        eventStore.mySearchEvents.length == 0
                    "
                />

                <!-- Show swiper screen -->
                <template v-else>
                    <template v-if="currentResult">
                        <!-- Show active search row, when there are people to swipe. -->
                        <MatchHeader />

                        <!-- Show player profile -->
                        <MatchAds v-if="matchStore.isAdScreen" />
                        <MatchPlayer v-else />

                        <!-- Buttons to swipe -->
                        <MatchButtons />
                    </template>

                    <!-- No more people to swipe -->
                    <NoEventFound v-else />
                </template>
            </div>
        </ion-content>
    </ion-page>
</template>

<script setup>
import _ from 'lodash';

const eventStore = useEventStore();
const matchStore = useMatchStore();
const route = useRoute();

const currentResult = computed(() => {
    return matchStore.currentResult;
});

const matcher = useMatcher();

// Loading screen watcher
matcher.setMatchLoadingScreenWatcher();

onIonChangedViewWillEnter(async () => {
    matchStore.initialize({
        forceSearch: route.meta.forceSearch ? true : false,
    });

    matcher.prefillFromActiveSearchOrRunQueue(route.query || {});

    matcher.start();
});

onIonViewWillLeave(() => {
    matchStore.opened = false;
});
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 2rem;
    justify-content: center;
}

.user {
    flex: 1;

    &:deep(.user__body) {
        height: 100%;
    }
}

:deep(.text-wrapper) {
    .icon {
        height: 4.8rem;
        display: block;
        margin: 0 auto;
    }

    h2 {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        margin: 6px 0;
    }

    p {
        margin: 0 auto;
        max-width: 24rem;
        font-size: 1.1rem;
        font-weight: 300;
        text-align: center;
    }
}

.btn-icon {
    width: 1.6rem;
    height: 1.6rem;

    &.--loupe {
        background: url($basePath + 'icons/loupe.svg') no-repeat center;
    }
}
</style>

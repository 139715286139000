<template>
    <AppHeader fullContent>
        <div class="heading">
            <strong>{{ __('Zvolené vyhľadávanie') }}</strong>

            <!-- prettier-ignore -->
            <div class="search-terms">
                <span v-if="(form.sport_ids||[]).length == 0">{{ __('Všetky športy') }}</span>
                <span v-else="(form.sport_ids||[]).length">{{ form.sport_ids?.length == 1 ? sportStore.getById(form.sport_ids[0])?.name : n__('%d šport', '%d športy', form.sport_ids.length, form.sport_ids.length) }}</span>

                <span>{{ form.date ? useDateFormatted(form.date) : __('kedykoľvek') }}</span>

                <span>{{ useDefaultFilterRadius() }}{{ __('km vzdialenosť') }}</span>
            </div>
        </div>
    </AppHeader>
    <ion-content class="--app-layout --safe-area">
        <div class="middle_wrapper">
            <div class="circles">
                <div>
                    <span></span>
                    <span></span>
                </div>
                <div>
                    <span></span>
                    <span></span>
                </div>
                <div><span></span></div>
                <div></div>
            </div>

            <div class="searching">
                <span>{{ __('Chvíľočku.') }}</span>
                <strong>
                    {{ __('Hľadáme ti parťáka v tvojom okolí....') }}
                </strong>
            </div>
        </div>
    </ion-content>
</template>

<script setup>
import _ from 'lodash';
const filterStore = useFilterStore();
const sportStore = useSportStore();
const form = computed(() => filterStore.form);
</script>

<style lang="scss" scoped>
.middle_wrapper {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.heading {
    text-align: center;

    strong {
        color: var(--ion-text-color);
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 1.2;
    }
}

.search-terms {
    display: flex;
    color: #81cbd3;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    :deep(span) {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        &:not(:last-child):after {
            content: '';
            width: 0.2rem;
            height: 0.2rem;
            border-radius: 50%;
            display: block;
            background: currentColor;
        }
    }
}

.circles {
    position: relative;
    width: 32rem;
    height: 32rem;
    margin: 0 auto 6rem auto;

    & > div {
        border: 1px dashed rgba(2, 30, 38, 0.35);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        span {
            width: 3.2rem;
            height: 3.2rem;
            border-radius: 50%;
            display: block;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:nth-child(1) {
            width: 32rem;
            height: 32rem;
            animation: rotate 35s linear infinite;

            span:first-child {
                top: 7.5rem;
                animation: inverseRotate 35s linear infinite;
                background: url($basePath + 'placeholders/search-user-1.jpg')
                    no-repeat center;
                background-size: cover;
            }

            span:last-child {
                top: 7.5rem;
                right: 0rem;
                animation: inverseRotate 35s linear infinite;
                background: url($basePath + 'placeholders/search-user-4.jpg')
                    no-repeat center;
                background-size: cover;
            }
        }

        &:nth-child(2) {
            width: 22rem;
            height: 22rem;
            animation: rotate 30s linear infinite;

            span:first-child {
                top: 10rem;
                right: -1.5rem;
                animation: inverseRotate 30s linear infinite;
                background: url($basePath + 'placeholders/search-user-2.jpg')
                    no-repeat center;
                background-size: cover;
            }

            span:last-child {
                bottom: 15rem;
                animation: inverseRotate 30s linear infinite;
                background: url($basePath + 'placeholders/search-user-5.jpg')
                    no-repeat center;
                background-size: cover;
            }
        }

        &:nth-child(3) {
            width: 12rem;
            height: 12rem;
            animation: rotate 25s linear infinite;

            span {
                top: 8rem;
                animation: inverseRotate 25s linear infinite;
                background: url($basePath + 'placeholders/search-user-3.jpg')
                    no-repeat center;
                background-size: cover;
            }
        }

        &:nth-child(4) {
            width: 4rem;
            height: 4rem;
            border: 0;
            background: url($basePath + 'icons/logo-symbol.svg') no-repeat
                center;
            border-radius: 0;
        }
    }
}

.searching {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    span {
        font-size: 1.1rem;
        font-weight: 300;
    }

    strong {
        font-size: 1.5rem;
        font-weight: 500;
    }
}

@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes inverseRotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}
</style>

<template>
    <div class="ad_wrapper">
        <div class="ad_container">
            <div v-if="adsStore.loading" class="text-center">
                <ion-spinner name="circular"></ion-spinner>

                <p>{{ __('Načítavame reklamu...') }}</p>
            </div>

            <AdMobAds v-model:loading="adsStore.loading" />

            <!-- prettier-ignore -->
            <div
                class="indicator"
                :class="{
                    '--active': !adsStore.loading,
                    '--loading': adsStore.loading,
                }"
                :style="{ '--duration': useBackendEnv('ADS_MINIMUM_DISPLAYED_SECONDS', 5)+'s' }"
            >
                <span></span>
            </div>
        </div>
    </div>
</template>

<script setup>
const adsStore = useAdsStore();
const matchStore = useMatchStore();

let _autoCloseAdOnErrorTimeout;
let _autoCloseAdOnDisplayedTimeout;

const clearTimeouts = () => {
    if (_autoCloseAdOnErrorTimeout) {
        clearTimeout(_autoCloseAdOnErrorTimeout);
    }

    if (_autoCloseAdOnDisplayedTimeout) {
        clearTimeout(_autoCloseAdOnDisplayedTimeout);
    }
};

const onAdvertisingDisplayed = () => {
    clearTimeouts();

    adsStore.isAdCloseDisabled = true;

    _autoCloseAdOnDisplayedTimeout = setTimeout(
        () => {
            adsStore.isAdCloseDisabled = false;
        },
        useBackendEnv('ADS_MINIMUM_DISPLAYED_SECONDS', 5) * 1000
    );
};

const autoCloseAdIfNotLoadedInTime = () => {
    watch(
        () => adsStore.loading,
        (isLoading) => {
            clearTimeouts();

            // prettier-ignore
            if (isLoading === true) {
                _autoCloseAdOnErrorTimeout = setTimeout(() => {
                    adsStore.loading = false;

                    // Add counter on add error
                    matchStore.addCounter(false);
                }, useBackendEnv('ADS_SKIP_IF_NOT_LOADED_IN_SECONDS', 10) * 1000);
            }

            // If ad is loaded
            else if (isLoading === false) {
                onAdvertisingDisplayed();
            }
        },
        { immediate: true }
    );
};

autoCloseAdIfNotLoadedInTime();

onUnmounted(() => {
    clearTimeouts();

    adsStore.reset();
});
</script>

<style lang="scss" scoped>
.ad_wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
}

.ad_container {
    border: 1px solid #ddd;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: #999;

    ion-spinner {
        width: 5rem;
        height: 5rem;
        color: #999;
    }

    p {
        margin-top: 1.5rem;
        font-size: 1.4rem;
        font-weight: 500;
    }
}

.indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.5rem;
    background: #ebebeb;
    z-index: 10;

    &.--loading {
        // width: 100%;
        // background: #000000;
    }

    &.--active {
        width: 100%;
        background: var(--ion-color-primary);
        animation-name: slideTo;
        animation-duration: var(--duration);
        animation-fill-mode: forwards;
        animation-timing-function: linear;
    }
}

@keyframes slideTo {
    0% {
        width: 100%;
    }

    100% {
        width: 0;
    }
}
</style>

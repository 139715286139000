<template>
    <div ref="adContainer"></div>
</template>

<script setup>
import {
    AdMob,
    AdmobConsentStatus,
    BannerAdPluginEvents,
    BannerAdPosition,
    BannerAdSize,
} from '@capacitor-community/admob';

const adContainer = ref(null);

const adsStore = useAdsStore();

const isAdLoading = defineModel('loading', { required: true, default: true });

onMounted(async () => {
    isAdLoading.value = true;

    try {
        if (adsStore.initialized === false) {
            await initialize();

            adsStore.initialized = true;
        }
    } catch (error) {
        console.error('Error initializing AdMob', error);
    }

    try {
        banner();
    } catch (error) {
        console.error('Error showing interstitial', error);
    }
});

async function initialize() {
    await AdMob.initialize();

    await askForTrackingConsent();
}

async function askForTrackingConsent() {
    const [trackingInfo, consentInfo] = await Promise.all([
        AdMob.trackingAuthorizationStatus(),
        AdMob.requestConsentInfo(),
    ]);

    if (trackingInfo.status === 'notDetermined') {
        await AdMob.requestTrackingAuthorization();
    }

    const authorizationStatus = await AdMob.trackingAuthorizationStatus();
    if (
        authorizationStatus.status === 'authorized' &&
        consentInfo.isConsentFormAvailable &&
        consentInfo.status === AdmobConsentStatus.REQUIRED
    ) {
        await AdMob.showConsentForm();
    }
}

async function banner() {
    AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
        isAdLoading.value = false;
    });

    AdMob.addListener(BannerAdPluginEvents.SizeChanged, (size) => {
        // Subscribe Change Banner Size
    });

    AdMob.showBanner({
        adSize: BannerAdSize.MEDIUM_RECTANGLE,
        adId: adsStore.getAdKey('swiper'),
        ...centerParams(),
        isTesting: isTrackingEnabled() ? false : true,
    });
}

const centerParams = () => {
    // Center on android is working fine..
    if (isPlatform('android')) {
        return {
            position: BannerAdPosition.CENTER,
            margin: 0,
        };
    }

    // On IOS we need calculate margin manually...
    return {
        position: BannerAdPosition.TOP_CENTER,

        // Margin on ios is working opposite way...
        margin: getBannerCenterMargin() * (isPlatform('ios') ? -1 : 1),
    };
};

const getBannerCenterMargin = () => {
    let safeTop = parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(
            '--ion-safe-area-top'
        )
    );

    const bannerHeight = 250;

    let value =
        parseInt(adContainer.value.getBoundingClientRect().top) -
        safeTop -
        bannerHeight / 2;

    return parseInt(value);
};

onUnmounted(() => {
    AdMob.removeBanner();
});
</script>

<style lang="scss" scoped></style>

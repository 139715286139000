<template>
    <ActiveSearchRow
        class="mb-2 animate__animated animate__pulse"
        :style="{ '--animate-duration': '400ms' }"
        :title="
            matchStore.eventPlayers >= 2
                ? matchStore.eventAttendeeCountText
                : __('Našli sme ti parťáka na')
        "
        :key="matchStore.currentResult.event.sport_id"
        :item="matchStore.currentResult"
        :cancelClick="() => router.push({ name: 'app-feed' })"
    />
</template>

<script setup>
const matchStore = useMatchStore();

const router = useRouter();
</script>

import _ from 'lodash';

const matchStore = useMatchStore(),
    eventStore = useEventStore(),
    filterStore = useFilterStore(),
    locationStore = useLocationStore(),
    authStore = useAuthStore();

const logMatchEvent = (eventName, params = {}, addEventInto = true) => {
    eventName = 'swiper_' + eventName;

    try {
        const currentResult = matchStore.currentResult;

        params.user_id = authStore.user?.id;
        params._counter = true;
        if (currentResult && currentResult?.event && addEventInto) {
            params.type = currentResult.event.type;
            params.event_id = currentResult.event.id;
            params.sport_id = currentResult.event.sport_id;
            params.opponent_id = currentResult.user?.id;
            params.is_match = useIsMe(matchStore.currentResult.event?.user_id)
                ? true
                : false;
            params.players_count = matchStore.eventPlayers;
        }

        logEvent(eventName, params);
    } catch (e) {
        console.error(e);
    }
};

const setMatchLoadingScreenWatcher = () => {
    watch(
        () => matchStore.loading.results,
        async (newValue, oldValue) => {
            if (newValue == false && oldValue === true) {
                await useSleep(useBackendEnv('MATCH_ANIMATION_SECONDS') * 1000);
            }

            matchStore.loading.screen = newValue;
        },
        { immediate: true }
    );
};

const checkMatchRequirements = () => {
    useThrowConnectionAlert();

    if (!authStore.loggedIn) {
        useOpenModal('register-modal');

        console.error('User is not logged in.');

        return false;
    }

    return true;
};

const swipeButtonAnimation = (el) => {
    const parent = el.closest('.button_wrapper');

    parent.classList.add('animate__animated');
    parent.classList.add('animate__pulse');

    setTimeout(() => {
        if (parent && parent.classList) {
            parent.classList.remove('animate__animated');
            parent.classList.remove('animate__pulse');
        }
    }, 500);
};

const swipeAction = async (isAccepted = false) => {
    // Count swipes clicks
    matchStore.addCounter(isAccepted);

    const { event, termine } = matchStore.currentResult;

    try {
        // prettier-ignore
        let response = await useAxios().$postOnline(
            '/api/events/' + event.id + '/termines/'+termine.id+'/attendees/match',
            {
                accepted : isAccepted ? 1 : 0,
                attendee_id: matchStore.currentResult.user?.id,
            }
        );

        useAutoAjaxResponse(response);

        return response;
    } catch (e) {
        useAutoAjaxResponse(e);
    }
};

const injectMyPendingAttendeesIntoResults = (allResults) => {
    let results = [];

    allResults.forEach((item) => {
        let { event, termine } = item;

        // When this item is mine, we want add all pending attendance
        if (useIsMe(event.user_id)) {
            (termine.attendees || []).forEach((attendee) => {
                results.push({
                    event,
                    termine,
                    user: attendee,
                });
            });
        } else {
            results.push({
                ...item,
                user: item.event.user,
            });
        }
    });

    return results;
};

const fetchResults = async () => {
    //If user has no position set, ask for his current position
    // prettier-ignore
    let position = useBackendEnv('SWIPER_IGNORE_LOCATION') ? {} : await locationStore.fetchCurrentPosition();

    // No position found or is not enabled. Show toast.
    if (!position) {
        return showLocationErrorToast(true);
    }

    try {
        let response = await useAxios()
            .loading((loading) => {
                matchStore.loading.results = loading;
            })
            .$postOnline('/api/search/matches', {
                ..._.pick(filterStore.form, ['sport_ids', 'date']),
                radius: authStore.user?.search_radius_km || null,
                position,
            });

        // prettier-ignore
        matchStore.results = injectMyPendingAttendeesIntoResults(flattenEventTermines(response.data.results));

        // My current active search
        matchStore.activeSearch = response.data.event;

        // Save active search into my events
        if (matchStore.activeSearch) {
            eventStore.addEvent(matchStore.activeSearch);
        }

        // If there is no other search result found, try another active search from queue, if queue is not empty
        if (!matchStore.currentResult && matchStore.searchesQueue.length > 0) {
            matchStore.goToNextActiveSearch();

            await fetchResults();
        }
    } catch (e) {
        console.error(e);
        //..
    }
};

const goToNextResult = async () => {
    //Reset previous accepted match
    matchStore.activeMatch = false;

    let resultsIndex = _.findIndex(
        matchStore.results,
        matchStore.currentResult
    );

    // Remove currently displayed result from results list
    if (resultsIndex > -1) {
        matchStore.results.splice(resultsIndex, 1);
    }

    //No next results to show are present, try load new results again.
    if (!matchStore.currentResult) {
        // Try fetch additional rows, to see some new search results
        await fetchResults();
    }
};

const swipeTo = async (isAccepted, e) => {
    if (matchStore.loading.request) {
        return;
    }

    matchStore.loading.request = isAccepted ? 'accept' : 'decline';

    swipeButtonAnimation(e.target);

    const eventParams = {
        direction: isAccepted ? 'right' : 'left',
    };

    // Ad screen
    if (matchStore.isAdScreen) {
        matchStore.addCounter(isAccepted);

        // prettier-ignore
        logMatchEvent('swipe_ad', {...eventParams}, false);
    }

    // Accept
    else if (checkMatchRequirements()) {
        logMatchEvent('swipe', { ...eventParams });

        if (isAccepted) {
            await acceptMatch(e);
        }

        // Decline
        else {
            await declineMatch(e);
        }
    }

    matchStore.loading.request = false;
};

const acceptMatch = async (e) => {
    let response = await swipeAction(true);

    // Register that active search event if is not mine. To be able open notifications if I will be accepted etc...
    if (useIsMe(matchStore.currentResult.event?.user_id) === false) {
        eventStore.addSearchEvent(matchStore.currentResult.event);
    }

    // If this is my event, that means both players are accepted, and it is match!
    if (useIsMe(response?.data.event?.user_id)) {
        logMatchEvent('match');

        matchStore.activeMatch = response?.data.event;

        // Update received event, because event-type may change, so we want
        // remove that search from the list.
        eventStore.addEvent(response?.data.event);
    } else {
        await goToNextResult();
    }
};

const declineMatch = async (e) => {
    await swipeAction(false);

    await goToNextResult();
};

const prefillFromActiveSearchOrRunQueue = (query) => {
    // Prefill from existing active search in query params
    if (query.eid && query.tid) {
        matchStore.fillFromEvent(query.eid, query.tid);
    }

    // If is not search from homepage, set active searchs queue one by one.
    else if (matchStore.forceSearch === false) {
        // We need clone events array, becuase we will be removing items from it.
        matchStore.searchesQueue = _.cloneDeep(eventStore.mySearchEvents);

        matchStore.goToNextActiveSearch();
    }
};

const start = () => {
    if (
        // When we have active searchs available, we can continue.
        eventStore.mySearchEvents.length > 0 ||
        //When force search from HP is triggered
        matchStore.forceSearch
    ) {
        matchStore.resetResults();

        fetchResults();
    }
};

export const useMatcher = () => {
    return {
        prefillFromActiveSearchOrRunQueue,
        setMatchLoadingScreenWatcher,
        injectMyPendingAttendeesIntoResults,
        fetchResults,
        goToNextResult,
        swipeTo,
        start,
    };
};

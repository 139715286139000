<template>
    <div class="text-wrapper">
        <img class="icon" src="~/assets/images/icons/no-match-results.svg" />
        <h2>{{ __('Žiadne aktívne vyhľadávania') }}</h2>

        <p>
            {{ __('Vyhľadávanie si môžeš nastaviť zvolením športu a času.') }}
        </p>

        <FormButton
            full
            visual="primary"
            class="mt-3"
            @click="useLazyClick($event, goTo)"
        >
            <span class="btn-icon --loupe"></span>

            {{ __('Zapnúť aktívne vyhľadávanie') }}
        </FormButton>
    </div>
</template>

<script setup>
const router = useRouter();

const goTo = async () => {
    await useRegisterNotifications();

    router.push({ name: 'app-feed' });
};
</script>

<style scoped lang="scss">
.text-wrapper {
    justify-content: center;
}
</style>

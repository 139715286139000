<template>
    <ProfileThumb
        class="animate__animated animate__pulse"
        :class="{ 'is-pending-match': isPendingMatch }"
        :style="{ '--animate-duration': '400ms' }"
        :key="currentResult.event?.id"
        :user="currentResult.user"
        :isPublic="true"
        :withDistance="true"
        :visibleBadgeIds="[currentResult.event.sport_id]"
        @profileClick="goToProfile($event)"
    >
        <template #header_top>
            <div class="d-flex">
                <div class="match_bage" v-if="isPendingMatch">
                    <div class="flame-icon"></div>
                    <span class="text">
                        {{ __('Chce si s tebou zahrať!') }}
                    </span>
                </div>

                <!-- prettier-ignore -->
                <SportTypeBadge
                     v-if="['event'].includes(currentResult.event.type)"
                    :title="matchStore.eventPlayers == 0 ? __('Nová partička') : matchStore.eventAttendeeCountText"
                />
            </div>
        </template>
    </ProfileThumb>
</template>

<script setup>
const matchStore = useMatchStore();

const currentResult = computed(() => {
    return matchStore.currentResult;
});

const isSearchEvent = computed(() => {
    return currentResult.value.event.type?.includes('-search') === true;
});

const isPendingMatch = computed(() => {
    return useIsMe(currentResult.value.event.user_id) === true;
});

const goToProfile = (e) => {
    if (isSearchEvent.value == false) {
        pushStateRoute(
            {
                name: 'app-event-slug',
                params: { slug: currentResult.value.event.slug },
                query: { t: currentResult.value.termine?.id },
            },
            {
                event: currentResult.value.event,
            }
        );
    } else {
        pushStateRoute(
            { path: '/app/account/' + currentResult.value.user.slug },
            { account: currentResult.value.user }
        );
    }
};
</script>

<style scoped>
.is-pending-match :deep(.swiper) .swiper-slide:before {
    --swiper-bottom-color: color-mix(
            in srgb,
            var(--ion-color-primary),
            transparent calc((1 - 0.75) * 100%)
        )
        100%;
}

.match_bage {
    display: flex;
    align-items: center;
    background-color: white;
    border: 2px solid var(--ion-color-primary);
    border-radius: 4rem;
    padding: 0.2rem 1rem 0.2rem 0.2rem;
    margin-right: 0.8rem;
    gap: 6px;

    .flame-icon {
        border-radius: 100%;
        width: 2rem;
        height: 2rem;
        background: var(--ion-color-primary)
            url('@/assets/images/icons/flame.svg') no-repeat center;
    }

    .text {
        font-family: var(--secondary-font-family);
        font-size: 1.4rem;
        color: var(--ion-text-color);
        font-weight: 700;
    }
}
</style>

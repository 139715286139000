import _ from 'lodash';
import moment from 'moment';

const eventStore = useEventStore();
const filterStore = useFilterStore();

const defaultCounter = () => ({
    total: 0,
    accepted: 0,
    declined: 0,
});

const defaultLoaders = () => ({
    screen: false,
    results: false,
    request: false,
});

export const useMatchStore = defineStore('match', {
    state() {
        return {
            // Is match screen opened
            opened: false,

            // Which active search are we using for searching events/players
            activeSearch: null,

            // Which active searches are in queue to be checked
            searchesQueue: [],

            // Is force search from homepage?
            forceSearch: false,

            // When two players are matched, this will be set to event
            activeMatch: false,

            // Counter of accepted and declined swipes
            counter: defaultCounter(),

            // Loadings states
            loading: defaultLoaders(),

            // Active event results to be swiped
            results: [],
        };
    },

    actions: {
        // Initialize matcher/swiper instance
        initialize({ forceSearch = false }) {
            console.log('Swiper initialized.');

            // Open match screen
            this.opened = true;

            // Reset active search
            this.activeSearch = null;

            // Reset active searches queue
            this.searchesQueue = [];

            // Set force search
            this.forceSearch = forceSearch;

            // Set during this session user has not accepted any match
            this.activeMatch = false;

            // Reset loadings
            this.loading = defaultLoaders();

            // Reset counter
            this.resetCounters();

            // Reset ads store
            useAdsStore().reset();
        },

        // Prefill active search from existing event and termine
        fillFromEvent(eventId, termineId) {
            if (!eventId || !termineId) {
                return;
            }

            let event = eventStore.getEvent(eventId),
                termine = eventStore.getTermine({
                    id: termineId,
                    event_id: eventId,
                });

            // Prefill from existing event
            if (event && termine) {
                filterStore.form.sport_ids = [event.sport_id];
                filterStore.form.date = termine.date
                    ? moment(termine.date).format('YYYY-MM-DD')
                    : null;

                console.log('Prefilled active search:', event.id, termine.id);
            }

            // Remove preselected item from queue. In case we are going from active search queue.
            _.remove(
                this.searchesQueue,
                (item) =>
                    item.event.id == eventId && item.termine.id == termineId
            );
        },
        goToNextActiveSearch() {
            // No active searches in queue
            if (this.searchesQueue.length == 0) {
                return;
            }

            const activeSearch = this.searchesQueue[0];

            this.fillFromEvent(activeSearch.event.id, activeSearch.termine.id);
        },
        resetResults() {
            this.results = [];
        },
        addCounter(isAccepted = false) {
            this.counter.total++;
            this.counter[isAccepted ? 'accepted' : 'declined']++;
        },
        resetCounters() {
            this.counter = defaultCounter();
        },
    },

    getters: {
        currentResult(state) {
            //Take next random event to be shown
            return _.shuffle(state.results || [])[0];
        },
        eventPlayers(state) {
            return state.currentResult?.event?.stats_players || 0;
        },
        eventAttendeeCountText(state) {
            // prettier-ignore
            return n__('Partička s %s členom', 'Partička s %s členmi', state.eventPlayers, state.eventPlayers);
        },
        isAdScreen(state) {
            const adsInterval = parseInt(
                useBackendEnv('SWIPER_ADS_EVERY_SWIPE')
            );

            // No adsInterval is set
            if (!adsInterval) {
                return false;
            }

            // Matcher is not opened yet
            if (!state.opened) {
                return false;
            }

            // No ads for this platform for swiper
            if (!useAdsStore().getAdKey('swiper')) {
                return false;
            }

            return (
                state.counter.total > 0 &&
                state.counter.total % adsInterval === 0
            );
        },
    },
});

<template>
    <div class="text-wrapper">
        <div>
            <img
                class="icon"
                src="~/assets/images/icons/looking-for-partner.svg"
            />
            <h2>{{ title }}</h2>

            <!-- prettier-ignore -->
            <p>{{ description }}</p>
        </div>

        <div class="mt-auto options">
            <div
                class="option"
                v-for="sport in sportsSorted"
                @click="
                    form.sport_ids = [sport.id];
                    router.push({ name: 'app-feed' });
                "
            >
                <img :src="sport.iconThumbnail" class="icon" />

                <div class="wrap-text">{{ sport.name }}</div>
            </div>
        </div>

        <FormButton
            v-if="authStore.loggedIn === false"
            full
            visual="primary"
            class="mt-3"
            @click="useOpenModal('login-modal')"
        >
            {{ __('Prihlásiť sa') }}
        </FormButton>
        <FormButton
            v-else
            full
            visual="primary"
            class="mt-3"
            @click="useLazyClick($event, registerSearch)"
        >
            {{ hasNotifications ? __('Dobre') : __('Zapnúť notifikácie') }}
        </FormButton>
    </div>
</template>

<script setup>
import _ from 'lodash';

const router = useRouter();
const authStore = useAuthStore();
const sportStore = useSportStore();
const filterStore = useFilterStore();
const matchStore = useMatchStore();

const form = computed(() => filterStore.form);

const hasNotifications = ref(
    notificationsSupported() == false || (await useIsNotificationsEnabled())
);

const sportsSorted = computed(() => {
    return _.shuffle(sportStore.sportsSorted.slice(0, 3));
});

const registerSearch = async () => {
    await useRegisterNotifications(true);

    router.push({ name: 'app-feed' });
};

const title = computed(() => {
    if (matchStore.activeSearch) {
        return matchStore.counter.accepted > 0
            ? __('Nájdeme ti ďalšieho parťáka!')
            : __('Nájdeme ti parťáka!');
    } else {
        return __('Aktuálne sme nikoho nenašli');
    }
});

const description = computed(() => {
    if (authStore.loggedIn == false) {
        return __('Ak chceš vytvoriť aktívne vyhľadávanie, prihlás sa.');
    }

    const sportId = form.value.sport_ids[0];

    if (matchStore.activeSearch) {
        return __(
            'Budeme sa ti snažiť čo najskôr nájsť parťáka na %s. Hneď ako sa nám to podarí, pošleme ti notifikáciu!',
            sportStore.getById(sportId)?.name || __('šport')
        );
    }

    if (!sportId) {
        return __('Ak chceš vytvoriť aktívne vyhľadávanie, vyber šport.');
    }

    // prettier-ignore
    return __('Ak si chceš nastaviť aktívne vyhľadávanie, vymaž nejake z predošlých.');
});
</script>

<style scoped lang="scss">
.text-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 70%;
}

.options {
    display: flex;
    gap: 1rem;
}

.option {
    aspect-ratio: 1/1;
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 11px;
    font-weight: 400;
    text-align: center;
    overflow: hidden;

    .icon {
        width: 3.2rem;
        height: 3.2rem;
        margin-bottom: 0.8rem;
    }

    .wrap-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: calc(100% - 0.5rem);
    }
}
</style>

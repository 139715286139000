<template>
    <div class="cta">
        <!-- decline -->
        <div class="button_wrapper">
            <FormButton
                visual="bordered"
                :loading="
                    matchStore.loading.request == 'decline' || isAdLoading
                "
                @click="matcher.swipeTo(false, $event)"
            >
                <span class="icon --dont-match"></span>
            </FormButton>
        </div>

        <!-- accept -->
        <div class="button_wrapper">
            <FormButton
                visual="primary"
                :loading="matchStore.loading.request == 'accept' || isAdLoading"
                @click="matcher.swipeTo(true, $event)"
            >
                <img class="icon --match" :src="sportIcon" />
            </FormButton>
        </div>
    </div>
</template>

<script setup>
const matchStore = useMatchStore();
const sportStore = useSportStore();
const adStore = useAdsStore();

const emit = defineEmits(['accept', 'decline']);

const sportIcon = computed(() => {
    return sportStore.getById(matchStore.currentResult.event.sport_id)
        ?.iconThumbnail;
});

const matcher = useMatcher();

// ADD throttle debounce:
const isAdLoading = computed(() => {
    return adStore.loading || adStore.isAdCloseDisabled;
});
</script>

<style lang="scss" scoped>
.cta {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;

    .button_wrapper {
        flex: 1;
        --animate-duration: 400ms;
    }

    .form-button {
        width: 100%;

        .icon {
            width: 2rem;
            height: 2rem;

            &.--dont-match {
                background: url($basePath + 'icons/dont-match.svg') no-repeat
                    center;
            }

            &.--match {
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
                    brightness(102%) contrast(102%);
            }
        }
    }
}
</style>
